body {
    background-image: url(imagens/Fundo2.jpg);
    background-repeat: repeat;
    background-size:100%;
    background-attachment: fixed;
    }


   /*Resultados*/

    .cadernoContainer {
        margin-top: 15px;
        background-image: url(imagens/CadernoLogo2.png);
        background-size: 400px;
        background-repeat: no-repeat;
        height: 540px;
        position: relative;
        left: 50%;
        transform: translate(-50%);
    }

    .caderno {
        margin-top: 69px;
        position: relative;
        float:left !important; 
        width: 300px;
        margin-left: 70px;
        color: #AD9282;
    }

    .tituloImc {
        font-size: x-large;
        font-weight: bold;
    }

    .significadoImc{
        margin-top: 15px;
    }

    .resultadoTitulo{
        font-weight: bold;
    }

    .fontCaderno {
        font-family: 'Lobster', cursive !important;
    }

    /*App IMC*/

    .appTela {
        margin-top: 16px;
        height: 540px;
        width: 300px;
        background-image: url(imagens/AppTela3.png);
        background-size: 100%;
        background-repeat: no-repeat;
        position: relative;
        left: 50%;
        transform: translate(-50%);
    }

    .appComponentes{
        margin-left: 22px;
        width: 255px;
        height: 350px;
        padding-top: 190px;
        text-align: center;
    }

    .appTitulo{
        font-size: 21px;
        font-weight: bolder;
    }

    .appTituloComponentes {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: -10px;
    }

    .exTexto{
        margin-right: 101px;
        color:rgb(99, 94, 94);
        font-size: 14px;
    }

    .botao{
        height: 40px;
        width: 100px;
    }

    /* Celular */

        /*Resultados*/

    @media (max-width: 999px) {

        .container {
            margin:0 auto;
            width: 97% !important;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
        }
       
        .cadernoContainer {
            background-size: 290px;
            width: 300px;
            height: 540px;
            
        }
    
        .caderno {
            margin-top: 20px;
            width: 200px;
            margin-left: 40px;
        }
    
        .tituloImc {
            font-size: 18px;
            font-weight: bold;
        }
    
        .significadoImc{
            margin-top: 11px;
            font-size: 14px;
        }
    
        .resultadoTitulo{
            font-weight: bold;
            margin-top: -10px !important;
        }
    
            /*App IMC */
    
        .appTela {
          margin: 0px;
          margin-top: 16px;

        }
    
    }

   